import { error } from "console";
import react from "react";
import PageError from "./ErrorMessages/pageError";
import ProfileError from "./ErrorMessages/profileError";
import JWTExpired from "./ErrorMessages/JWTexpired";
import BadRequest from "./ErrorMessages/BadRequest";

interface data{
    errCode?: number,
    errMessage?: string,
}

interface Props{
    data: data
}

interface State{
}

class Error extends react.Component<Props, State> {
    /**
     *
     */
    constructor(props:Props) {
        super(props);
        
    }

    render(): react.ReactNode {
        let {errCode, errMessage} =  this.props.data
        return(
        <>
        {errMessage === "JWT expired" && errCode === 401 && <JWTExpired />}
        {errMessage === "Forbidden page request" && errCode === 403 && <PageError />}
        {errMessage === "Forbidden profile" && errCode === 403 && <ProfileError />}
        {errMessage === "Generic error" && errCode === 400 && <BadRequest/>}
        </>
        )
    }
}

export default Error