import React from "react";

interface Props{

}
interface State{

}

class BadRequest extends React.Component<Props, State>{
    /**
     *
     */
    constructor(props: Props) {
        super(props);
        
    }

    render(): React.ReactNode {
        return(
            <main>
                <div className="container">
                <div className="alert alert-danger" role="alert">
                    <a onClick={e => window.history.back()} style={{cursor: "pointer"}}>
                    De pagina die U bezoekt klopt niet. <br/>
                    U kunt door <u>hier</u> te klikken terug gaan naar uw vorige pagina. <br/>
                    Error: "Bad request"
                    </a>
                </div>
                </div>
            </main>
        )
    }
}

export default BadRequest;