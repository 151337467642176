import { ChangeEvent, useEffect, useState } from "react";
import Button from 'react-bootstrap/Button';
import Modal from 'react-bootstrap/Modal';
import { withProps } from "../../../helpers";
import API from "../../../db/APIrequests";
import { Formik } from "formik";
import { object, string } from "yup";
import { yupReturnText } from "../../../helpers/validationEnums";

interface Triggers {
  context: {
    clicked: boolean,
    returnValue: Function,
    userId: number,
  }
}

type hidden = {
  "field1": boolean,
  "field2": boolean,
  "field3": boolean
}

const passwordSchema = object({
  oldPassword: string().min(8, yupReturnText.passwordLengthInvalid).required(yupReturnText.passwordRequired),
  password: string().min(8, yupReturnText.passwordLengthInvalid).required(yupReturnText.passwordRequired),
})

const APIrequests = new API();

function PasswordModal(Props: Triggers) {
  const [show, setShow] = useState(false);
  const [returnValue, setReturnValue] = useState("")
  const [hidden, setHidden] = useState({
    "field1": true,
    "field2": true,
    "field3": true
  })
  const [check, setCheck] = useState("");
  const [compare, setCompare] = useState(false);

  const handleClose = () => { setReturnValue("Closed"); setShow(false) };
  const handleShow = () => setShow(true);
  const handleCheck = (e: ChangeEvent<HTMLInputElement>) => setCheck(e.target.value)

  const togglePasswordHidden = (target: keyof (hidden)) =>
    setHidden((prev) => {
      const update = { ...prev }
      update[target] = !prev[target]
      console.log(update)
      return update
    })


  useEffect(() => {
    if (Props.context.clicked !== false) {
      handleShow();
      setReturnValue("");
    }
  }, [Props.context.clicked])


  useEffect(() => {
    if (returnValue) {
      Props.context.returnValue.call(returnValue, returnValue)
      if (returnValue === "Clicked") {
        setShow(false)
      }
    }
  }, [returnValue])

  const comparePasswords = () => {
    setCompare(true)
  }

  return (
    <div
      className="modal show"
      style={{ display: 'block', position: 'initial' }}
    >
      <Formik
        initialValues={{ oldPassword: "", password: "" }}
        validationSchema={passwordSchema}
        onSubmit={(values, actions) => {
          const data = {
            oldPassword: values.oldPassword,
            password: values.password
          }
          APIrequests.updatePassword(Props.context.userId, data)
        }}
        validateOnChange={true}
      >
        {props => (
          <Modal
            show={show}
            onHide={handleClose}
            backdrop="static"
            keyboard={false}
          >

            <Modal.Header closeButton onClick={handleClose}>
              <Modal.Title>Wachtwoord wijzigen</Modal.Title>
            </Modal.Header>

            <Modal.Body>

              <form onSubmit={props.handleSubmit}>
                <div className="row mt-3">
                  <div className="col-12 col-sm-4">
                    <label htmlFor="exampleInputPassword1" className="form-label" >Oud wachtwoord</label>
                  </div>
                  <div className="col-12 col-sm-8 revealer-container">
                    <input
                      type={hidden.field1 === true ? "password" : "text"}
                      className="form-control"
                      id="oldPassword"
                      name="oldPassword"
                      onChange={props.handleChange}
                      onBlur={props.handleBlur}
                      value={props.values.oldPassword}
                    />
                    <i className={"revealer-icon fa-solid " + (hidden.field1 === true ? "fa-eye-slash" : "fa-eye")} onClick={e => togglePasswordHidden("field1")}></i>
                    {props.errors.oldPassword && props.touched.oldPassword ?
                      <div className="alert alert-danger" role="alert">
                        {props.errors.oldPassword}
                      </div>
                      : null
                    }
                  </div>
                </div>
                <div className="row mt-3">
                  <div className="col-12 col-sm-4">
                    <label htmlFor="exampleInputPassword1" className="form-label" >Nieuw wachtwoord</label>
                  </div>
                  <div className="col-12 col-sm-8 revealer-container">
                    <input
                      type={hidden.field2 === true ? "password" : "text"}
                      className="form-control"
                      id="password"
                      name="password"
                      onChange={props.handleChange}
                      onBlur={props.handleBlur}
                      value={props.values.password}
                    />
                    <i className={"revealer-icon fa-solid " + (hidden.field2 === true ? "fa-eye-slash" : "fa-eye")} onClick={e => togglePasswordHidden("field2")}></i>
                    {props.errors.password && props.touched.password ?
                      <div className="alert alert-danger" role="alert">
                        {props.errors.password}
                      </div>
                      : null
                    }
                  </div>
                </div>
                <div className="row mt-3">
                  <div className="col-12 col-sm-4">
                    <label htmlFor="exampleInputPassword1" className="form-label" >Bevestig wachtwoord</label>
                  </div>
                  <div className="col-12 col-sm-8 revealer-container">
                    <input
                      type={hidden.field3 === true ? "password" : "text"}
                      className="form-control"
                      id="password"
                      name="password"
                      value={check}
                      onChange={handleCheck}
                      onInput={comparePasswords}
                    />
                    <i className={"revealer-icon fa-solid " + (hidden.field3 === true ? "fa-eye-slash" : "fa-eye")} onClick={e => togglePasswordHidden("field3")}></i>
                  </div>
                </div>
                {props.values.password !== check && compare === true &&
                  <div className="alert alert-danger" role="alert">
                    Uw nieuw wachtwoord komt niet overeen met het bevestigd wachtwoord
                  </div>
                }
              </form>

            </Modal.Body>

            <Modal.Footer>
              <Button variant="secondary" onClick={handleClose}>Sluiten</Button>
              <Button variant="primary" type="submit" onClick={e => { setReturnValue("Clicked"); props.submitForm()}}>Bevestigen</Button>
            </Modal.Footer>

          </Modal>
        )}
      </Formik>
    </div>
  );
}

export default withProps(PasswordModal)
