import { redirect } from "react-router-dom";
import axios from "axios";

interface JWTdata {
  data: {
    ID: number,
    user: string,
    PermissionLevel: "Test" | "User" | "Admin"
  }
  iat: EpochTimeStamp,
  exp: number,
}

let baseUrl = process.env.REACT_APP_BASEURL

class API {
  url: string;
  css: string;

  _loggedIn: boolean // tracks if a token is set in the browser. Renders parts of the navbar menu.
  token: string | null;
  headers: {headers:{authorization: string}}
  verified: boolean;
  tokenData: JWTdata | undefined
  
  constructor(){

    if(!baseUrl){
      throw new Error("Process.env is not set")
    }

    this.url =  baseUrl
    this.css = this.url + "/css/styles-sass.css"


    this.token = window.localStorage.getItem("authorization");
    this.headers =  { headers: { "authorization" : this.token ? this.token : ""}}
    this._loggedIn =  false

    this.verified = false

    if(this.headers == undefined || ""){
      redirect("./login")
      window.location.assign("./login")
    }
    if(this.token){
      // JSON payload
      this.tokenData = JSON.parse(window.atob(this.token.split(".")[1]))
      let validToken = this.tokenData ? ((new Date(this.tokenData.exp * 1000).getTime() > new Date().getTime()) ? true : false) : false
      this._loggedIn = validToken === true ? true : false; 
    }
  }

  async loginUser(data: any){
    if(this.headers){
      window.localStorage.removeItem("authorization")
    }
    return axios.post(`${this.url}/API/authentication/Login`, data).then((response) => {
    if(response.data.token){
      this.token = response.data.token
      if(this.headers != undefined){
        window.localStorage.removeItem("authorization")
        window.localStorage.setItem("authorization", response.data.token)
      }
      window.localStorage.setItem("authorization", response.data.token)
    }
    return response}).catch(e => {return e})
  }

  async fetchData(url: string){
    return axios.get(this.url + url, this.headers).then(response => {return response}).catch(e => {return e})
  }

  logout(){
    window.localStorage.removeItem("authorization")
    window.location.assign("/login")
  }

  async activateAccount(url:string){
    return axios.post(url, this.headers).then(response => {return response}).catch(e => {return e})
  }

  async updateEditPage(url:string, data:any){
    return axios.put(this.url + url, data, this.headers).then(response => {return response}).catch(e => {return e})
  }

  async UploadFile(path:string, data:any){
    let uploadData = new FormData();
    uploadData.append("client-photo", data)

    return axios.post(this.url + "/API/upload/"+path, uploadData , this.headers).then(response =>{return response}).catch(e => {return e})
  }

  async createAccount(data:any){
    return axios.post(this.url+"/API/authentication/CreateAccount", data, this.headers).then(response => {return response}).catch(e => {return e})
  }

  async createProfile(url:string){
    return axios.post(this.url+url, this.headers).then(response => {return response}).catch(e => {return e})
  }

  async deleteProfile(url:string){
      return axios.delete(this.url +url, this.headers).then(response => {return response}).catch(e => {return e})
  }

  async updateAdminDetails(url:string, data:any){
    return axios.put(this.url+url, data, this.headers).then(response => {return response}).catch(e => {return e})
  }

  async fetchPDF(clientID:number){
    return axios.get(this.url + "/API/pdf/download/" + clientID, {responseType: 'blob', headers: {"authorization": this.token}}).then(response => {return response}).catch(e => {return e})
  }

  async updatePassword(userId: number, data: any){
    return axios.put(this.url + "/API/authentication/ResetPassword/" + userId, data, this.headers).then(response => {return response}).catch(e => {return e});
  };
}

export default API;
