import { useOutletContext, useParams } from 'react-router-dom';
import { dataBox } from './db/model';

export function withProps(Component:any) { // pass props & params to class component from router
  return (props: any) => <Component {...props} data={useOutletContext()} params={useParams()} />;
}



export function convertStringDateToDateFormat(value:any):Date | undefined{
  let dateParts = value.split("-")
  if(dateParts[2] != undefined && dateParts[2].length === 4){
    let date = new Date(dateParts[2], dateParts[1]-1, dateParts[0]);
    return date
  }
}


export function stringifyData(data:Array<dataBox>){
  const optionalData = { };
  data.forEach((rowData, index) => {

    let rowPrefix = rowData.columnName
    let prefixObj = `{"${rowPrefix}" : ""}`


    let returndata = JSON.parse(prefixObj)
    returndata[rowPrefix] = JSON.stringify(rowData)
      Object.assign(optionalData, optionalData, returndata)

  })

  return optionalData
}

export function verifyDateFormat(date: string):boolean{

  if(date == ""){
    return true
  }

  let dateParts = date.split("-")

  let convertToDate = new Date(dateParts[2] +"-" +dateParts[1] +"-"+ dateParts[0])

  return (convertToDate.toDateString() != "Invalid Date" ? false : true)
}
