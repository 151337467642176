import react from "react";
import API from "../db/APIrequests";
import { withProps } from "../helpers";
import Error from "./components/Error"
import Navbar from "./navbar";

interface Params {
  id: number,
  profileID: number
}

interface Props{
  params: Params
}

interface State{
  data: any
  loadingPreview: boolean
  errorMessage?: string
  errCode?: number
}

const apirequest = new API();
class PDFPreview extends react.Component<Props, State>{

  /**
   *
   */
  constructor(props:Props) {
    super(props);

    this.state = { data:undefined, loadingPreview: false }

  }

  componentDidMount(): void {
    this.fetchData();
  }

  fetchData(){
    const {id, profileID} = this.props.params;
    const url = "/API/pdf/" + id +"/fetchPDF/" + profileID;
    apirequest.fetchData(url)
      .then(response => {
        if (response.status === 200){
          this.setState({data:response.data})
        }
        else{
          this.setState({errorMessage:response.response.data, errCode: response.response.status})
        }
      })
      .catch(e => console.log(e))
  }

  handlePreview(){
    this.setState({loadingPreview: true})
    apirequest.fetchPDF(this.props.params.profileID)
      .then(response => { 
        const fileURL = window.URL.createObjectURL(response.data);
        // Setting various property values
        let alink = document.createElement('a');
        alink.href = fileURL;
        alink.download = 'preview.pdf';
        alink.click();
        this.setState({loadingPreview: false})
      })
      .catch(e => console.log(e))
  }


  render(): react.ReactNode {
    const data = this.state.data

    const markup = {__html: `${data}`}
    return(
      <>
      <div id={"users"}>
      <Navbar context={{ setButton1: "Print PDF", setButton2: "Terug naar bewerken", button1Click: (value: number) => {this.handlePreview()}, button2Click: (value: number) => {window.location.assign(window.location.pathname.split("/viewPDF")[0])}}}></Navbar>
      {this.state.loadingPreview === true && 
      <div className="container mt-5">
        <div className="row">
          <div className="col-12">
            <div className="alert alert-warning text-center" role="alert" >
              
              <div className="container-loader">
                <span className="loader-anim"></span>
                <span className="loader-text">Uw PDF wordt voorbereid. Een moment a.u.b.</span>
              </div>

            </div>
          </div>
        </div>
      </div>
      }

      {this.state.data && apirequest._loggedIn === true && this.props.params.id == apirequest.tokenData?.data.ID && <div dangerouslySetInnerHTML={markup}/>}


      {this.state.errCode !== undefined && this.state.errorMessage !== undefined && <Error data={{errCode: this.state.errCode, errMessage: this.state.errorMessage}}/>}
      </div>
      </>
    )
  }
}


export default withProps(PDFPreview)
