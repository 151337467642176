import react, { ReactNode } from "react"
import API from "../db/APIrequests";
import { withProps } from "../helpers";

export type forcedButtonNames = "Opslaan" | "Login" |"Voorbeeld PDF" | "Disabled" | "Nieuw Profiel" | "Print PDF" | "Terug naar bewerken" | "Aanmelden" |"Auto-Save" | "Opgeslagen" | undefined
interface Props{
  context: {
    setButton1: forcedButtonNames,
    setButton2: forcedButtonNames,
    button1Click: Function,
    button2Click: Function
  }
}

interface State{
  firstButtonName: forcedButtonNames,
  secondButtonName: forcedButtonNames,
  saveMessage: undefined | "Saved"
}

const icons = {
  Plus: "fa-square-plus", // aanmelden (home page)
  Lock: "fa-lock", // login (home page)
  User: "fa-user", // gebruiker/logged in
  List: "fa-rectangle-list", // beheer account
  Info: "fa-circle-info", // info / hulp nodig
  Open: "fa-arrow-right-from-bracket", // log out
  Add: "fa-add", //toevoegen /aanmaken /nieuwe manager
  Save: "fa-save", // save icon
  PDF: "fa-file-pdf", // pdf preview/download etc.
  Bewerken: "fa-pen-to-square", // back to previous page
  Print:"fa-print", // print PDF (PDF preview)
  Share: "fa-share", //deel online (PDF preview)
  AutoSave: "fa-sync fa-spin"
}

const APIrequests = new API();

class Navbar extends react.PureComponent<Props, State>{
  /**
   *
   */
  constructor(Props:Props) {
    super(Props);
    
    this.state = {firstButtonName: undefined, secondButtonName: undefined, saveMessage: undefined}

    this.firstButtonHandler = this.firstButtonHandler.bind(this);
    this.secondButtonHandler =  this.secondButtonHandler.bind(this)
  }

  firstButtonHandler(event:any){
    if(event.type == "click"){
      this.props.context.button1Click.call("button 1 clicked and calls parent")
    }
  }

  secondButtonHandler(event: any){
    if(event.type == "click"){
      this.props.context.button2Click.call("button 2 clicked and calls parent")
    }
  }

  setButtonIcon(target:string){
    switch(target){
      case "Opslaan":
        return icons.Save
      case "Voorbeeld PDF":
        return icons.PDF
      case "Disabled":
        return ""
      case "Nieuw Profiel":
          return icons.Add
      case "Print PDF":
        return icons.Print
      case "Terug naar bewerken":
        return icons.Bewerken
      case "Aanmelden":
        return icons.Add
      case "Auto-Save":
          return icons.AutoSave
      case "Opgeslagen":
          return icons.Save
      case "Login":
          return icons.Lock
      default:
        return "fa-square"
    }
  }

  buttonNameHandler(){
    let {setButton1, setButton2} =  this.props.context

    if(setButton1){
      this.setState({firstButtonName:setButton1})
    }
    if(setButton2){
      this.setState({secondButtonName: setButton2})
    }
  }

  render(){
    const {firstButtonName, secondButtonName} = this.state
    const {setButton1, setButton2} =  this.props.context

   if(setButton1 !== undefined || setButton2 !== undefined){
    this.buttonNameHandler();
   }

    return(
      <>
        <header className="sticky-top">

          <div className="container-fluid">
            <div className="row">
              <div className="col-12">

                {/* <!-- NAV --> */}
                <nav className="navbar navbar-expand-lg">
                  <div className="container">

                    {/* <!-- Logo --> */}
                    <a className="navbar-brand" href={APIrequests.url}></a>

                    {/* <!-- Hamburger --> */}
                    <button className="navbar-toggler" type="button" data-bs-toggle="collapse" data-bs-target="#navbarSupportedContent" aria-controls="navbarSupportedContent" aria-expanded="false" aria-label="Toggle navigation">
                      <span className="fa-solid fa-bars"></span>
                    </button>

                    {/* <!-- Collapsable menu --> */}
                    <div className="collapse navbar-collapse" id="navbarSupportedContent">

                      {/* <!-- Items --> */}
                      <ul className="navbar-nav me-auto mb-2 mb-lg-0" >
                        <li className="nav-item" >
                          &nbsp;
                        </li>
                        <li className="nav-item">
                          &nbsp;
                        </li>
                        <li className="nav-item" >
                          &nbsp;
                        </li>
                      </ul>

                      {/* <!-- Buttons --> */}
                      <form className="justify-content-end" role="search">

                        {secondButtonName !== "Disabled" && secondButtonName !== "Login" && secondButtonName !== "Aanmelden" && APIrequests._loggedIn === true &&
                          <button className={"btn " + (secondButtonName == "Auto-Save" ? "btn-autosave" : secondButtonName == "Opslaan" ? "btn-saveme" : "btn-secondary")} key={secondButtonName} type="button" onClick={(event: React.MouseEvent<HTMLElement>) => this.secondButtonHandler(event)} disabled={secondButtonName === "Opgeslagen" ? true : false}                    >
                            <span key={secondButtonName + "span"} className={"fa-solid " + this.setButtonIcon(secondButtonName ? secondButtonName : "")}></span> {secondButtonName}
                          </button>
                        }
                        {(secondButtonName == "Aanmelden" || secondButtonName === "Login") &&
                          <button className={"btn btn-secondary"} key={secondButtonName} type="button" onClick={() => window.location.assign(secondButtonName === "Login" ? "Login" : "Aanmelden")}>
                            <span key={secondButtonName + "span"} className={"fa-solid " + this.setButtonIcon(secondButtonName ? secondButtonName : "")}></span> {secondButtonName}
                          </button>
                        }

                        {firstButtonName !== "Disabled" && APIrequests._loggedIn === true &&
                          <button className={"btn btn-primary"} key={firstButtonName} type="button" onClick={(event: React.MouseEvent<HTMLElement>) => this.firstButtonHandler(event)}>
                            <span key={firstButtonName + "span"} className={"fa-solid " + this.setButtonIcon(firstButtonName ? firstButtonName : "")}></span> {firstButtonName}
                          </button>
                        }
                      </form>
                      {/* <!-- Profile dropdown --> */}
                      {APIrequests._loggedIn === true &&
                        <ul className="navbar-nav">
                          <li className="nav-item dropdown" >
                            <a className="nav-link dropdown-toggle btn btn-outline-light" key={"Dropdown-Button-User"} href="#" role="button" data-bs-toggle="dropdown" aria-expanded="false">
                              <span key="dropdown-span" className="fa-solid fa-user"></span>
                            </a>
                            <ul className="dropdown-menu dropdown-menu-end">
                              <li>
                                <a className="dropdown-item" href="#" key="Mijn Account" onClick={e => window.location.assign("/account/overview/" + APIrequests.tokenData?.data.ID)}>
                                  <span key="account-span" className="fa-solid fa-user"></span> Mijn account
                                </a>
                              </li>
                              <li key="Beheer profielen">
                                <a className="dropdown-item" href="#" key="Beheer profielen" onClick={e => window.location.assign("/account/overview/" + APIrequests.tokenData?.data.ID)}>
                                  <span key="profiel span" className="fa-solid fa-rectangle-list"></span> Beheer profielen
                                </a>
                              </li>
                              <li><hr className="dropdown-divider" key={"Divider"} /></li>
                              <li>
                                <a className="dropdown-item" href="#" key="Hulp nodig">
                                  <span key="hulp-span" className="fa-solid fa-circle-info"></span> Hulp nodig?
                                </a>
                              </li>
                              <li ><hr className="dropdown-divider" key="dropdown-divider-2" /></li>
                              <li >
                                <a className="dropdown-item" href="#" key="logout" onClick={e => APIrequests.logout()}>
                                  <span key="logout-span" className="fa-solid fa-arrow-right-from-bracket"></span> Uitloggen
                                </a>
                              </li>
                            </ul>
                          </li>
                        </ul>
                      }

                    </div>
                  </div>
                </nav>

              </div>
            </div>
          </div>
        </header>
      </>
    )
  }
}


export default withProps(Navbar)
