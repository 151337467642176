import React from "react";
import { Outlet } from "react-router-dom";
import API from "../db/APIrequests";
import {optionalData, clienten, admins, adminDetails} from "../db/model"
import {withProps } from "../helpers";
import GenericModal from "./components/Modals/editModal"
import Error from "./components/Error"
import Navbar from "./navbar";

interface contextType{
  optionalData: optionalData,
  updateOptionalData: Array<any>,
}

interface Params {
  id: number,
  profileID: number
}

interface Props{
  params: Params
}
interface State{
  data?: clienten
  optional?: optionalData
  updatedOptionalData: optionalData | undefined
  file?: any
  profielnaam: string | undefined
  leeftijd: number | undefined
  uploadError: string
  adminDetails?: adminDetails
  triggerModal: boolean
  errorMessage?: string
  errorCode?: number
  button1: string,
  button2: "Opslaan" | "Opgeslagen" | "Auto-Save"
}

const APIrequests = new API();

class Edit extends React.Component<Props, State> {

  file:any;
  autoSaveTimer: NodeJS.Timeout | null = null

  /**
   *
   */
  constructor(Props:Props) {
    super(Props);

    this.state = {updatedOptionalData: undefined, leeftijd: undefined , uploadError: "", triggerModal: false, profielnaam: undefined, button1: "Voorbeeld PDF", button2: "Opgeslagen"}

    this.fetchData =  this.fetchData.bind(this);

    this.handleDateInput =  this.handleDateInput.bind(this);
  }

  componentDidMount(): void {
    this.fetchData();
  }

  async fetchData(){
    let url = "/API/profile/" + this.props.params.id + "/edit/" + this.props.params.profileID;
    await APIrequests.fetchData(url)
      .then(response =>
         {if(response.status === 200){
          this.setState({data:response.data.data, optional: response.data.optional, adminDetails: response.data.adminDetails})
         }
         if(response.status !== 200){
          this.setState({errorMessage: response.response.data, errorCode: response.response.status})
         }
        })
      .catch(e => console.log(e))
  }

  handleChange(e:any){
    let value = e.target.value
    let {data} = this.state

    if(data){
      data.profielnaam = value !== undefined ? value.trimStart() : undefined
    }
    this.setState({data: data, profielnaam: value !== undefined ? value.trimStart(): undefined})
  }

  handleDateInput(e:any){
    let value:string = e.target.value
    let {data} = this.state

    if(data){
      data.leeftijd = parseInt(e.target.value)
      this.setState({leeftijd: parseInt(value), data: data})
    }
  }

  async handleUpload(e:any){

    let profileID = this.props.params.profileID
    if(e.target.files){
      this.file = e.target.files[0]
    }

    try{
      let result = await APIrequests.UploadFile(this.props.params.profileID.toString() ,this.file)

      if(result.data.newUploadPath){
        let {data} = this.state
        let path_photo =  result.data.newUploadPath

        if(data)
          data.path_foto = path_photo
        this.setState({data:data})
      }

      this.file = undefined
    }
    catch(e){
      console.log(e)
    }
  }

  async saveData(e:any, resetRow?:string){
    const url = "/API/profile/" + this.props.params.id + "/edit/" + this.props.params.profileID
    let {data, optional, updatedOptionalData} =  this.state

    try{

      if(e === "Preview dataloss prevention"){
        let sendData = {
          NoReload: true,
          data,
          updatedOptionalData
        }
        await APIrequests.updateEditPage(url, sendData)
        .then(response => {
          if(response.data === "Profile updated" && response.status ===200){
            window.location.assign(window.location.pathname +"/viewPDF")
          }
        })
        return
      }
      if(e === "AutoSave"){
        let sendData = {
          NoReload: true,
          data,
          updatedOptionalData
        }
        await APIrequests.updateEditPage(url, sendData).then(
          () => 
          this.setState({profielnaam: undefined, leeftijd: undefined, updatedOptionalData: undefined})
        )
        return 
      }

    if(updatedOptionalData == undefined){
      let sendData = {
        data,
        resetRow
      }
      await APIrequests.updateEditPage(url, sendData)
        .then(response => {
          if(response.data === "Profile updated" && response.status ===200){
            this.fetchData().then(
              () =>
              this.setState({leeftijd: undefined, updatedOptionalData: undefined, button2: "Opgeslagen"})
            )
            return
          }
        })
    }
    if(updatedOptionalData != undefined){
      let sendData = {
        data,
        updatedOptionalData,
        resetRow
      }
      await APIrequests.updateEditPage(url, sendData)
        .then(response => {
          if(response.data === "Profile updated" && response.status ===200){
            this.fetchData().then( () =>
              this.setState({profielnaam: undefined, leeftijd: undefined, updatedOptionalData: undefined, button2: "Opgeslagen"})

            );
            return
          }
        })
    }
    }
    catch(e){
      console.log(e)
    }
  }

  resetRow(target: string){
    this.saveData("hello", target);
  }

  autoSaveData(e: any){
    if(e === "AutoSave"){ 

    }
  }

  handleSaveStates(){
    let {profielnaam, leeftijd, updatedOptionalData, button2, data} = this.state

    if(button2 === "Opgeslagen" && profielnaam !== undefined && Boolean(data?.profielnaamOpgeslagen) !== true){
      this.setState({button2:"Opslaan"})
    }
    if(button2 === "Opgeslagen" && (leeftijd || updatedOptionalData)){
      this.setState({button2:"Opslaan"})
    }
    if(button2 === "Opslaan" && (leeftijd || updatedOptionalData)){
      if(this.autoSaveTimer !== null){
        clearTimeout(this.autoSaveTimer)
      }
      this.autoSaveTimer = setTimeout(() => {this.saveData("AutoSave"); this.setState({button2: "Auto-Save"})}, 10000)
    }

    if(button2 === "Auto-Save" && profielnaam !== undefined && profielnaam !== ""){
      setTimeout(() => {
        this.setState({button2: "Opslaan"})
      }, 2000);
    }else if(button2 === "Auto-Save"){
      setTimeout(() => {
        this.setState({button2: "Opgeslagen"})
      }, 2000);
    }
  }

  handleSaveClick(){
    let {data} =  this.state
    if(data === undefined){
      return
    }
    if((Boolean(data.profielnaamOpgeslagen) === false) && data.profielnaam !== ""){
      this.setState({triggerModal: true, button2: "Opgeslagen"})
      if(this.autoSaveTimer != undefined){
        clearTimeout(this.autoSaveTimer)
      }
    }
    else{
      this.saveData("Manual save")
      if(this.autoSaveTimer != undefined){
        clearTimeout(this.autoSaveTimer)
      }
      this.setState({button2: "Opgeslagen"})
    }
  }

  render(): React.ReactNode {
    let {data, profielnaam, leeftijd, uploadError, adminDetails, optional, updatedOptionalData, button2} =  this.state

    this.handleSaveStates();

    return(
      <>
      <div id={"users"}>
      <Navbar context={{ setButton1: this.state.button1, setButton2: this.state.button2, button1Click: (value: number) => { this.saveData("Preview dataloss prevention");window.location.assign( window.location.pathname + "/viewPDF")}, button2Click: (value: number) => {this.handleSaveClick()}}}></Navbar>

      {APIrequests._loggedIn === true && this.props.params.id == APIrequests.tokenData?.data.ID && this.state.errorMessage === undefined &&
      <main className="edit-pdf">
        <GenericModal context={{clicked: this.state.triggerModal, returnValue: (event:any) =>{event === "Closed" ? this.setState({triggerModal: false}) : this.saveData(event)}, name: data?.profielnaam}} />

        <div className="container">

          {/* <!-- ADMIN --> */}
          <div className="row">
            <div className="col-12">
              <div className="header-info">
                <h1>Profiel bewerken</h1>
                <p>
                  Pas de onderstaande gegevens aan en print het naar een PDF of uw printer.
                </p>
                <hr/>
                
                <h2>Contactpersoon</h2>
                <p>
                  Aan de onderzijde van het profiel tonen wij altijd een contactpersoon. Momenteel is dat uw gebruikersnaam. In de toekomst wordt dit veld aanpasbaar.
                </p>
              </div>
            </div>
          </div>

          <div className="row mb-2 align-items-center">
            <div className="col-12 col-md-3">
              <label form="naam" className="form-label">Naam:</label>
            </div>
            <div className="col-12 col-md-6">
              {(adminDetails?.Voornaam ? adminDetails.Voornaam + " ": "") + (adminDetails?.Tussenvoegsel ? adminDetails.Tussenvoegsel + " ": "") + (adminDetails?.Achternaam ? adminDetails.Achternaam : "")}
              {!adminDetails?.Voornaam && !adminDetails?.Achternaam && <p className="no-margin"> U heeft nog geen naam op uw profiel ingevuld</p>}
            </div>
          </div>

          <div className="row mb-2">
            <div className="col-12">
              <hr/>
            </div>
          </div>

          {/* <!-- CLIENT --> */}
          <div className="row">
            <div className="col-12">
              <div className="header-info">
                <h2>Over wie gaat het?</h2>
                <p>
                  Deze persoon staat centraal op de gegenereerde pagina / PDF. Voer de relevante gegevens in.
                </p>
              </div>
            </div>
          </div>

          <div className="row mb-2 align-items-center">
            <div className="col-12 col-md-3">
              <label form="formFile" className="form-label">Foto:</label>
            </div>
            <div className="col-12 col-md-6">
              <div className="input-group">
                <input className="form-control" type="file" id="foto" name="client-foto" onChange={(e) => {this.handleUpload(e)}}/>
              </div>
              {uploadError != "" &&
                <div className="alert alert-danger" role="alert">
                  {uploadError}
                </div>
                }
            </div>
            <div className="col-12 col-md-3">
              {!this.state.data?.path_foto &&
                <div className="preview-container">
                  <img src={APIrequests.url + "/uploads/default.png"} className="img-thumbnail preview-photo"/>
                </div>
              }
              {this.state.data?.path_foto &&
                <div className="preview-container">
                  <img src={APIrequests.url+this.state.data?.path_foto} className="img-thumbnail preview-photo"/>
                </div>
              }
            </div>
          </div>

          <div className="row mb-2 align-items-center">
            <div className="col-12 col-md-3">
              <label form="naam" className="form-label">Hoe wil men worden aangesproken?</label>
            </div>
            <div className="col-12 col-md-6">
              <input className="form-control" id="naam" placeholder="Bvb Mevr. Jansen of liever Jeanne Jansen?" name="naam" onChange={e => this.handleChange(e)} value={this.state.data?.profielnaam ? this.state.data.profielnaam : ""} required/>
            </div>
          </div>

          <div className="row mb-2 align-items-center">
            <div className="col-12 col-md-3">
              <label form="geboortedatum" className="form-label">Leeftijd:</label>
            </div>
            <div className="col-12 col-md-6">
              <input className="form-control" id="geboortedatum" name="leeftijd" onChange={e => this.handleDateInput(e)} value={data?.leeftijd ?  data.leeftijd : ""} maxLength={3}/>
            </div>
          </div>

          <div className="row mb-2">
            <div className="col-12">
              <hr/>
            </div>
          </div>


          {/* <!-- BALLOONS --> */}
          <div className="row">
            <div className="col-12 col-sm-9">
              <div className="header-info">
                <h2>Gespreksonderwerpen</h2>
                <p>
                  Voeg hier de onderwerpen toe waar je over zou kunen praten met deze persoon. Bijvoorbeeld hobbies, werkgevers, de (klein)kinderen, partners, etc. Wil men liever eigen onderwerpen bepalen, dan kan men een van de standaard invoervelden zelf aanpassen (momenteel nog niet geactiveerd).
                </p>
              </div>
            </div>
            <div className="col-12 col-md-3">
              <div className="header-info">
                <h2>Legenda</h2>
                <p>
                  <span className="fa-solid fa-toggle-on"></span> (De)activeer onderwerp<br/>
                  <span className="fa-solid fa-trash-can"></span> Maak invoervelden leeg<br/>
                  <span className="strong">*</span> Maximaal 75 karakters tekst<br/>
                </p>
              </div>
            </div>
          </div>

          <div className="row">
            <div className="col-12 col-md-3">
              <h3>Onderwerp kop <span className="d-inline d-md-none">en tekst</span></h3>
            </div>
            <div className="d-none d-md-block col-md-6">
              <h3>Onderwerp tekst *</h3>
            </div>
            <div className="d-none d-md-block col-md-3">
              <h3>Acties</h3>
            </div>
          </div>


          <Outlet context={{optionaldata: this.state.optional, setOptionalData: (values:any) => {this.setState({updatedOptionalData: values})}, resetRow: (row:string) => {this.resetRow(row)}}}/>

          <div className="row mb-3">
            <div className="col-12">
              <hr/>
              <p>Disclaimer: Deze applicatie is nog in ontwikkeling.</p>
            </div>
          </div>

        </div>
      </main>
    }
    
    {this.state.errorMessage !== undefined && this.state.errorCode !== undefined &&
      <Error data={{errCode: this.state.errorCode, errMessage: this.state.errorMessage}} />
    }
    </div>
    </>
  )}
}

export default withProps(Edit)
