import react from "react";


interface Props{

}
interface State{

}

class ProfileError extends react.Component<Props,State>{
    /**
     *
     */
    constructor(props:Props) {
        super(props);
    }

    render(){
        return(
            <main>
                <div className="container">
                <div className="alert alert-danger"> 
                    <a onClick={e => window.history.back()} style={{cursor: "pointer"}}>
                    U bent niet bevoegd om dit profiel te bekijken. <br/>
                    U kunt door <u>hier</u> te klikken terug gaan naar uw vorige pagina. <br/>
                    Error: "Viewing Unauthorized profile"
                    </a>
                </div>
                </div>
            </main>
        )
    }
}

export default ProfileError;