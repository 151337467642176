import react from "react"
import APIrequest from "../db/APIrequests"
import { withProps } from "../helpers";
import { Formik, FormikValues } from "formik";
import {object,string} from "yup";
import { yupReturnText } from "../helpers/validationEnums";
import Navbar from "./navbar";

interface Props {
};
interface State {
  Alert: string,
  passwordHidden: boolean,
};


let createAccountSchema = object({
  email: string().email(yupReturnText.emailInvalid).required(yupReturnText.emailRequired),
  password: string().min(8, yupReturnText.passwordLengthInvalid).required(yupReturnText.passwordRequired),
  PermissionLevel: string().matches(/(User|Test)/, "Valid: Test || User").required("Permissions nodig"),
});

const API =  new APIrequest();

class CreateAccount extends react.Component<Props, State> {

  /**
   *
   */
  constructor(props:Props) {
    super(props)

    this.state = {Alert: "", passwordHidden: true}

  }

  login(formikdata: FormikValues){
    const user = formikdata.email
    const password = formikdata.password
    const permissionlevel = formikdata.PermissionLevel

    const data = {
      username: user,
      password: password,
      PermissionLevel: permissionlevel
    }

    API.createAccount(data)
      .then(response => {
        if(response) {
          if(response.response && response.response.status == 400 && response.response.data.AccountAlreadyExists === true){
            this.setState({Alert: "Email is al in gebruik" })
          }
          if(response.data.creationSuccess === true){
            // window.location.assign("/login")
          }
        }})
      .catch(e => console.log(e))
  }

  togglePasswordHidden(){
    this.setState({passwordHidden: !this.state.passwordHidden})
  }


  render(){

    return(
    <>
    {API.tokenData && API.tokenData.data.PermissionLevel === "Admin" && 
      <div id={"users"}>
      <Navbar context={{ setButton1: "Disabled", setButton2: "Login", button1Click: (value: number) => {}, button2Click: (value: number) => {}}}></Navbar>
      <main>
        <div className="container">
          <div className="row d-flex justify-content-center">
            <div className="col-12 col-md-8 col-lg-6 col-xl-5 col-xxl-4">
              <Formik
                initialValues={{ email: "", password: "", PermissionLevel: "User"}}
                validationSchema={createAccountSchema}
                onSubmit={(values, actions) => {
                  this.login(values)
                }}
                validateOnChange={false}
              >
                {props => (<form onSubmit={props.handleSubmit}>
                  <div className="container">
                    <div className="row">
                      <div className="col-12">
                        <h1>Cree&euml;r een account. </h1>
                      </div>
                    </div>
                    <div className="row mt-3">
                      <div className="col-12 col-sm-4">
                        <label htmlFor="exampleInputEmail1" className="form-label">E-mailadres</label>
                      </div>
                      <div className="col-12 col-sm-8">
                        <input
                          type="text"
                          className="form-control"
                          id="email"
                          aria-describedby="emailHelp"
                          name="email"
                          onChange={props.handleChange}
                          value={props.values.email}
                          onBlur={props.handleBlur}
                          required />
                        {this.state.Alert &&
                          <div className="alert alert-danger" role="alert">
                            {this.state.Alert}
                          </div>}
                        {props.errors.email && props.touched.email ?
                          <div className="alert alert-danger" role="alert">
                            {props.errors.email}
                          </div>
                          : null
                        }
                        <div id="emailHelp" className="form-text">We zullen uw e-mailadres nooit met anderen delen.</div>
                      </div>
                    </div>
                    <div className="row mt-3">
                      <div className="col-12 col-sm-4">
                        <label htmlFor="exampleInputPassword1" className="form-label" >Wachtwoord</label>
                      </div>
                      <div className="col-12 col-sm-8 revealer-container">
                        <input
                          type={this.state.passwordHidden === true ? "password" : "text"}
                          className="form-control"
                          id="password"
                          name="password"
                          onChange={props.handleChange}
                          onBlur={props.handleBlur}
                          value={props.values.password}
                        />
                        <i className={"revealer-icon fa-solid "+ (this.state.passwordHidden === true ?  "fa-eye-slash" : "fa-eye")} onClick={e => this.togglePasswordHidden()}></i>
                        {props.errors.password && props.touched.password ?
                          <div className="alert alert-danger" role="alert">
                            {props.errors.password}
                          </div>
                          : null
                        }
                      </div>
                    </div>
                    <div className="row mt-3">
                      {API.tokenData && API.tokenData.data.PermissionLevel === "Admin" &&
                    <><div className="col-12 col-sm-4">
                                <label htmlFor="exampleInputPassword1" className="form-label">PermissionLevels</label>
                              </div><div className="col-12 col-sm-8">
                                  <input
                                    type="text"
                                    className="form-control"
                                    id="email"
                                    aria-describedby="emailHelp"
                                    name="PermissionLevel"
                                    onChange={props.handleChange}
                                    value={props.values.PermissionLevel}
                                    onBlur={props.handleBlur}
                                    required />
                                  {props.errors.PermissionLevel && props.touched.PermissionLevel ?
                                    <div className="alert alert-danger" role="alert">
                                      {props.errors.PermissionLevel}
                                    </div>
                                    : null}
                                </div></>
  }
                    </div>
                    <div className="row mt-3">
                      <div className="col-12 d-flex justify-content-end">
                        <button className="btn btn-primary" type="submit">Registreer</button>
                      </div>
                    </div>
                  </div>
                </form>
                )}
              </Formik>
            </div>
          </div>
        </div>
      </main>
      </div>
  }

{
    API.tokenData?.data.PermissionLevel !== "Admin" &&
    <div> Het is momenteel niet mogelijk om nieuwe accounts aan te maken.</div>
  }
    </>
    )
  }
}

export default withProps(CreateAccount)
