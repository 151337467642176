import react from "react";
import { Formik, FormikErrors, useFormik } from "formik";
import { object,string} from "yup";
import { yupReturnText } from "../helpers/validationEnums";

interface Props{

}

interface State{
    errors: number
}

let loginSchema = object({
    email: string().email(yupReturnText.emailInvalid).required(yupReturnText.emailRequired),
    password: string().required(yupReturnText.passwordRequired)
})

class FormikTest extends react.Component<Props, State> {

    constructor(props:Props) {
        super(props);

        this.state = {errors: 50}
        
    }

    render(): react.ReactNode {

        let {errors} = this.state
        return(
            <>
                <main>
                    <div className="container">
                        <div className="row d-flex justify-content-center">
                            <div className="col-12 col-md-8 col-lg-6 col-xl-5 col-xxl-4">
                                <Formik
                                    initialValues={{ email: "", password: "" }}
                                    validationSchema={loginSchema}
                                    onSubmit={(values, actions) => {
                                        alert(JSON.stringify(values, null, 2))
                                    }}
                                    validateOnChange={false}
                                    validateOnBlur
                                >
                                    {props => (
                                        <form onSubmit={props.submitForm}>
                                            <div className="container">
                                                <div className="row">
                                                    <div className="col-12">
                                                        <h1>Login</h1>
                                                    </div>
                                                </div>
                                                {errors === 2 &&
                                                    <div className="alert alert-danger mt-2" role="alert">
                                                        Uw login gegevens zijn niet correct.
                                                    </div>}
                                                {errors === 3 &&
                                                    <div className="alert alert-danger mt-2" role="alert">
                                                        Er is iets verkeerd gegaan. Het kan zijn dat u nog een account moet aanmaken.
                                                    </div>}
                                                <div className="row mt-3">
                                                    <div className="col-12 col-sm-4">
                                                        <label htmlFor="exampleInputEmail1" className="form-label">E-mailadres</label>
                                                    </div>
                                                    <div className="col-12 col-sm-8">
                                                        <input 
                                                            type="email" 
                                                            className="form-control" 
                                                            id="email" 
                                                            aria-describedby="emailHelp" 
                                                            name="email" 
                                                            onChange={props.handleChange} 
                                                            onBlur={props.handleBlur}
                                                        />
                                                         {props.errors.email &&
                          <div className="alert alert-danger" role="alert">
                            {props.errors.email}
                          </div>}
                                                        <div id="emailHelp" className="form-text">We zullen uw e-mailadres nooit met anderen delen.</div>
                                                    </div>
                                                </div>
                                                <div className="row mt-3">
                                                    <div className="col-12 col-sm-4">
                                                        <label htmlFor="exampleInputPassword1" className="form-label" >Wachtwoord</label>
                                                    </div>
                                                    <div className="col-12 col-sm-8">
                                                        <input 
                                                            type="password" 
                                                            className="form-control" 
                                                            id="password" 
                                                            name="password" 
                                                            onChange={props.handleChange}
                                                            onBlur={props.handleBlur}
                                                            />
                                                             {props.errors.password &&
                          <div className="alert alert-danger" role="alert">
                            {props.errors.password}
                          </div>}
                                                    </div>
                                                </div>
                                                <div className="row mt-3">
                                                    <div className="col-12 d-flex justify-content-end">
                                                        <button className="btn btn-primary" type='submit'>Inloggen</button>
                                                    </div>
                                                </div>
                                            </div>
                                        </form>
                                    )}
                                </Formik>
                            </div>
                        </div>
                    </div>
                </main>
            </>
        )
    }

}

export default FormikTest;