import react, { useEffect, useState } from "react";
import Button from 'react-bootstrap/Button';
import Modal from 'react-bootstrap/Modal';
import { withProps } from "../helpers";

interface Triggers{
  context:{
    clicked : boolean,
    returnValue : Function,
    action: string,
    target?: string | number,
  }
}

function DefaultModal(Props: Triggers) {
  const [show, setShow] = useState(false);
  const [action, setAction] = useState("")
  const [returnValue, setReturnValue] = useState("")

  const handleClose = () => {setReturnValue("Closed"); setShow(false)};
  const handleShow = () => setShow(true);

  useEffect(() => {
    if(Props.context.clicked !== false){
      handleShow();
      setReturnValue("");
    }
  }, [Props.context.clicked])

  useEffect(() => {
    if(returnValue){
      Props.context.returnValue.call(returnValue, returnValue, Props.context.target)
      if(returnValue === "Clicked"){
        setShow(false)
      }
    }
  },[returnValue])

  useEffect(() => {
    setAction(Props.context.action)
  }, [Props.context.action])

  return (
    <div
      className="modal show"
      style={{ display: 'block', position: 'initial' }}
    >
      <Modal 
        show={show} 
        onHide={handleClose}
      >
        
        <Modal.Header closeButton onClick={handleClose}>
          <Modal.Title>Bevestig uw actie</Modal.Title>
        </Modal.Header>

        <Modal.Body>
          <p>
            {action}
          </p>
        </Modal.Body>

        <Modal.Footer>
          <Button variant="secondary" onClick={handleClose}>Annuleren</Button>
          <Button variant="primary" onClick={e => {setReturnValue("Clicked")}}>Bevestigen</Button>
        </Modal.Footer>
        
      </Modal>
    </div>
  );
}

export default withProps(DefaultModal)
